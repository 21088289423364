import React from "react";
import { Link } from "gatsby";
import { Flex, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import MobileNav from "./MobileNav";
//@ts-ignore
import logo from "../images/nav/logo.svg";
//@ts-ignore
import rogomi from "../images/nav/rogomi.svg";

import "../assets/css/nav.css";

type NavProps = {
  active: string;
};

const setActiveNav = (active: string, pageName: string): string => {
  if (active === pageName) {
    return "active";
  }

  return "inactive";
};

export default function Nav(props: NavProps) {
  const { active } = props;
  const [scroll, setScroll] = React.useState(0);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.25,
      },
    },
  };

  const items = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const navContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const navItems = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const navHovered = {
    color: "#32AAB5",
  };

  React.useEffect(() => {
    let mounted = true;

    if (typeof window !== undefined && mounted) {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY);
      });
    }

    return () => {
      window.removeEventListener("scroll", () => {
        mounted = false;
      });
    };
  });

  return (
    <Flex
      alignItems="center"
      px="2rem"
      w="100%"

      // position={"fixed"}
      // backgroundColor={scroll > 100 ? "#ffff" : ""}
      // boxShadow={scroll > 100 ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : ""}
      // transition={"box-shadow 0.5s"}
      // zIndex="999"
      // borderBottomLeftRadius={10}
      // borderBottomRightRadius={10}
    >
      <motion.div
        initial="hidden"
        animate="show"
        variants={container}
        style={{
          padding: 10,
        }}
      >
        <motion.div variants={items}>
          <Image
            boxSize="3rem"
            objectFit="cover"
            src={logo}
            alt="rogomi_logo.svg"
            onClick={() => {
              window.location.replace("/");
            }}
            _hover={{
              cursor: "pointer",
            }}
          />
        </motion.div>
        <motion.div variants={items}>
          <Image
            width="3.5rem"
            objectFit="contain"
            src={rogomi}
            onClick={() => {
              window.location.replace("/");
            }}
            alt="rogomi.svg"
            _hover={{
              cursor: "pointer",
            }}
          />
        </motion.div>
      </motion.div>
      <Flex display={["none", "none", "none", "flex", "flex"]} w="100%">
        <motion.ul
          variants={navContainer}
          initial="hidden"
          animate="show"
          className="nav-ul"
        >
          <motion.li
            whileHover={navHovered}
            className={setActiveNav(active, "home")}
            variants={navItems}
          >
            <Link to="/">Home</Link>
          </motion.li>
          <motion.li
            whileHover={navHovered}
            className={setActiveNav(active, "services")}
            variants={navItems}
          >
            <Link to="/services">Our Work</Link>
          </motion.li>
          <motion.li
            whileHover={navHovered}
            className={setActiveNav(active, "about_us")}
            variants={navItems}
          >
            <Link to="/about-us">Our Story</Link>
          </motion.li>
          <motion.li
            whileHover={navHovered}
            className={setActiveNav(active, "contact-us")}
            variants={navItems}
          >
            <Link to="/contact-us">Contact Us</Link>
          </motion.li>
        </motion.ul>
      </Flex>
      <MobileNav active={active} setActiveNav={setActiveNav} />
    </Flex>
  );
}
