import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  List,
  ListItem,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

// @ts-ignore
import FooterSpiral from "../images/Footer/spiralfooter.svg";
import "../assets/css/footer.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { PrivacyPolicy } from "./PrivacyPolicy";

gsap.registerPlugin(ScrollTrigger);

export default function Footer() {
  const [openFooter, setFooterOpen] = useState(false);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".footerlogos",
        start: "center center",
      },
    });

    tl.fromTo(
      ".footerLogos",
      { opacity: 0 },
      { opacity: 1, duration: 1 }
    ).fromTo(".subtext", { opacity: 0 }, { opacity: 1, duration: 1 });
  }, []);

  return (
    <>
      <Flex
        backgroundImage={FooterSpiral}
        backgroundRepeat={"no-repeat"}
        backgroundPosition={"600px"}
        backgroundColor={"#06667C"}
        flexDirection="row"
        // borderTopLeftRadius={10}
        // borderTopRightRadius={10
        minH="400px"
        w="100%"
        className="bigfoot"
        pb={"2rem"}
      >
        {/* <Spiral /> */}
        <Flex flexDirection="column" px="2rem" pt="2rem" w="100%">
          <Flex
            flexWrap="wrap"
            w="100%"
            flexDirection="row"
            wrap="wrap"
            justifyContent={"space-between"}
          >
            <Flex
              alignItems={"center"}
              justifyContent="space-between"
              flexDirection={"row"}
              flexWrap={"wrap"}
              w="100%"
              my="1rem"
            >
              <Flex flexDirection={"column"} className="footerLogos">
                {/* <Image src={logo} boxSize="5rem" /> */}
                {/* <Image src={rogomi} width="8rem" /> */}
                <Text
                  fontFamily={"Open Sans"}
                  color="#ffff"
                  fontSize={"2rem"}
                  fontWeight={800}
                >
                  Contact Us
                </Text>
              </Flex>
              <Text className="subtext">
                Together, we awaken possibilities.
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems="center"
            flexWrap={"wrap"}
            minH="210px"
          >
            <Flex flexDirection="column" py="2rem">
              <List className="contact_us_list_item" spacing={2}>
                <ListItem>+632 8883 5302</ListItem>
                <ListItem>inquire@rogomi.com</ListItem>
                <ListItem>
                  19/F Marco Polo, Sapphire Road Ortigas Center,
                  <br /> Pasig City Metro Manila 1600 Philippines
                </ListItem>
              </List>
            </Flex>
            <Flex
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems="center"
            >
              <Link href="https://www.facebook.com/Rogomi/" w="3rem">
                <FaFacebook color="#fff" size={"2rem"} />
              </Link>
              <Link href="https://www.instagram.com/rogomiapps" w="3rem">
                <FaInstagram color="#fff" size={"2rem"} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/rogomi-inc-/"
                w="3rem"
              >
                <FaLinkedin color="#fff" size={"2rem"} />
              </Link>
              <Link href="https://twitter.com/rogomiapps" w="3rem">
                <FaTwitter color="#fff" size={"2rem"} />
              </Link>
            </Flex>
          </Flex>

          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems="center"
            mt="3rem"
            flexWrap={"wrap"}
          >
            <Text my="0.25rem" className="copy">
              Copyright &copy; 2022 Rogomi Inc. All rights reserved.
            </Text>
            <Text
              my="0.25rem"
              className="copy"
              onClick={() => setFooterOpen((prev) => !prev)}
              _hover={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Modal
        isOpen={openFooter}
        onClose={() => setFooterOpen((prev) => !prev)}
        isCentered
        size={"5xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent py="2rem" mx="1rem">
          <ModalHeader>
            <Text
              style={{
                color: "#919e9c",
                fontFamily: "Open Sans",
                fontSize: "1.5rem",
              }}
            >
              Privacy Policy
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py="2rem`">
            <PrivacyPolicy />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
