import React from "react";

export default function () {
  return (
    <svg
      width="275"
      height="450"
      viewBox="0 0 423 858"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="lotusIphone"
    >
      <path
        d="M420 230H421.5C422.328 230 423 230.672 423 231.5V341.5C423 342.328 422.328 343 421.5 343H420V230Z"
        fill="#446087"
      />
      <path
        d="M2.99988 366L1.49987 366C0.671445 366 -0.000127257 365.328 -0.000127184 364.5L-0.000121414 298.5C-0.000121342 297.672 0.671452 297 1.49988 297L2.99988 297L2.99988 366Z"
        fill="#446087"
      />
      <path
        d="M2.99988 275L1.49987 275C0.671445 275 -0.000127257 274.328 -0.000127184 273.5L-0.000121414 207.5C-0.000121342 206.672 0.671452 206 1.49988 206L2.99988 206L2.99988 275Z"
        fill="#446087"
      />
      <path
        d="M2.99988 170L1.49988 170C0.671449 170 -0.000124396 169.328 -0.000124323 168.5L-0.000121613 137.5C-0.000121541 136.672 0.671451 136 1.49988 136L2.99988 136L2.99988 170Z"
        fill="#446087"
      />
      <path
        d="M3 91C3 66.8033 3 54.705 6.18133 44.9139C12.611 25.1254 28.1254 9.61099 47.9139 3.18133C57.705 0 69.8033 0 94 0H329C353.197 0 365.295 0 375.086 3.18133C394.875 9.61099 410.389 25.1254 416.819 44.9139C420 54.705 420 66.8033 420 91V767C420 791.197 420 803.295 416.819 813.086C410.389 832.875 394.875 848.389 375.086 854.819C365.295 858 353.197 858 329 858H94C69.8033 858 57.705 858 47.9139 854.819C28.1254 848.389 12.611 832.875 6.18133 813.086C3 803.295 3 791.197 3 767V91Z"
        fill="#102948"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M329.4 3H93.6C69.6199 3 58.1042 3.02467 48.8409 6.0345C29.9658 12.1674 15.1674 26.9658 9.0345 45.8409C6.02467 55.1042 6 66.6199 6 90.6V767.4C6 791.38 6.02467 802.896 9.0345 812.159C15.1674 831.034 29.9658 845.833 48.8409 851.966C58.1042 854.975 69.6199 855 93.6 855H329.4C353.38 855 364.896 854.975 374.159 851.966C393.034 845.833 407.833 831.034 413.966 812.159C416.975 802.896 417 791.38 417 767.4V90.6C417 66.6199 416.975 55.1042 413.966 45.8409C407.833 26.9658 393.034 12.1674 374.159 6.0345C364.896 3.02467 353.38 3 329.4 3ZM7.13238 45.2229C4 54.8634 4 66.7756 4 90.6V767.4C4 791.224 4 803.137 7.13238 812.777C13.4631 832.261 28.7389 847.537 48.2229 853.868C57.8634 857 69.7756 857 93.6 857H329.4C353.224 857 365.137 857 374.777 853.868C394.261 847.537 409.537 832.261 415.868 812.777C419 803.137 419 791.224 419 767.4V90.6C419 66.7756 419 54.8634 415.868 45.2229C409.537 25.7389 394.261 10.4631 374.777 4.13238C365.137 1 353.224 1 329.4 1H93.6C69.7756 1 57.8634 1 48.2229 4.13238C28.7389 10.4631 13.4631 25.7389 7.13238 45.2229Z"
        fill="#446087"
      />
      <path
        d="M8 89C8 66.6646 8 55.4969 10.9366 46.459C16.8717 28.1927 31.1927 13.8717 49.459 7.93661C58.4969 5 69.6646 5 92 5H331C353.335 5 364.503 5 373.541 7.93661C391.807 13.8717 406.128 28.1927 412.063 46.459C415 55.4969 415 66.6646 415 89V769C415 791.335 415 802.503 412.063 811.541C406.128 829.807 391.807 844.128 373.541 850.063C364.503 853 353.335 853 331 853H92C69.6646 853 58.4969 853 49.459 850.063C31.1927 844.128 16.8717 829.807 10.9366 811.541C8 802.503 8 791.335 8 769V89Z"
        fill="#121515"
      />
      <path d="M329 0H338V5H329V0Z" fill="#48627E" />
      <path d="M415 86H420V95H415V86Z" fill="#48627E" />
      <path d="M3 86H8V95H3V86Z" fill="#48627E" />
      <path d="M3 763H8V772H3V763Z" fill="#48627E" />
      <path d="M415 763H420V772H415V763Z" fill="#48627E" />
      <path d="M85 853H94V858H85V853Z" fill="#48627E" />
      <mask
        id="mask0_304_342"
        maskUnits="userSpaceOnUse"
        x="22"
        y="19"
        width="379"
        height="820"
      >
        <path
          d="M121 53C108.822 53 99 43.1503 99 31V25C99 21.6863 96.3214 19 93 19H91.09C70.5136 19 60.2254 19 52.0419 22.166C39.6989 26.9411 29.9411 36.6989 25.166 49.0419C22 57.2254 22 67.5136 22 88.09V769.91C22 790.486 22 800.775 25.166 808.958C29.9411 821.301 39.6989 831.059 52.0419 835.834C60.2254 839 70.5136 839 91.09 839H331.91C352.486 839 362.775 839 370.958 835.834C383.301 831.059 393.059 821.301 397.834 808.958C401 800.775 401 790.486 401 769.91V88.09C401 67.5136 401 57.2254 397.834 49.0419C393.059 36.6989 383.301 26.9411 370.958 22.166C362.775 19 352.486 19 331.91 19H330C326.679 19 324 21.6863 324 25V31C324 43.1503 314.178 53 302 53H121Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_304_342)">
        <rect
          x="22"
          y="1"
          width="379"
          height="793"
          rx="20"
          fill="url(#paint0_linear_304_342)"
        />
        <path
          d="M202 623C247.765 687.449 309.967 730 309.967 730L310.231 729.808C313.545 727.531 373.527 685.623 418 623H202Z"
          fill="#80D3C3"
        />
        <path
          d="M330.217 360.288C273.529 227.733 109.886 167 109.886 167C109.886 167 38.129 321.155 94.7834 453.711C151.471 586.235 315.114 647 315.114 647C315.114 647 386.871 492.811 330.217 360.288Z"
          fill="#446087"
        />
        <path
          d="M530.203 453.711C473.519 586.235 309.885 647 309.885 647C309.885 647 238.131 492.811 294.782 360.288C351.467 227.733 515.1 167 515.1 167C515.1 167 586.888 321.157 530.203 453.711Z"
          fill="#446087"
        />
        <path
          d="M588.68 546.371C485.195 649.11 310.11 646.997 310.11 646.997C310.11 646.997 302.837 478.369 406.354 375.661C509.838 272.923 684.889 275.003 684.889 275.003C684.889 275.003 692.163 443.631 588.68 546.371Z"
          fill="#32AAB5"
        />
        <path
          d="M685 275.388C684.967 275.195 684.967 275.003 684.967 275.003C684.967 275.003 509.879 272.922 406.373 375.662C302.836 478.37 310.11 647 310.11 647H310.509L685 275.388Z"
          fill="#758CA3"
        />
        <path
          d="M213.651 375.661C110.172 272.923 -64.8899 275.003 -64.8899 275.003C-64.8899 275.003 -72.1555 443.631 31.3332 546.371C134.813 649.11 309.891 646.997 309.891 646.997C309.891 646.997 317.131 478.369 213.652 375.661"
          fill="#32AAB5"
        />
        <path
          d="M310 646.645V646.997C310 646.997 134.871 649.11 31.3612 546.37C-72.1576 443.63 -64.8898 275 -64.8898 275H-64.5145L309.994 646.645"
          fill="#758CA3"
        />
        <path
          d="M437 387.516C437 530.844 310 647 310 647C310 647 183 530.844 183 387.516C183 244.187 310 128 310 128C310 128 437 244.187 437 387.516Z"
          fill="#32AAB5"
        />
        <path
          d="M310 128.256L309.736 128C309.736 128 183 244.187 183 387.516C183 530.844 309.736 647 309.736 647C309.736 647 309.868 646.872 310 646.775V128.256Z"
          fill="#758CA3"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M258 44C262.418 44 266 40.4183 266 36C266 31.5817 262.418 28 258 28C253.582 28 250 31.5817 250 36C250 40.4183 253.582 44 258 44Z"
        fill="#262C2D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M258 41C260.761 41 263 38.7614 263 36C263 33.2386 260.761 31 258 31C255.239 31 253 33.2386 253 36C253 38.7614 255.239 41 258 41Z"
        fill="#121515"
      />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M258 35C258.552 35 259 34.5523 259 34C259 33.4477 258.552 33 258 33C257.448 33 257 33.4477 257 34C257 34.5523 257.448 35 258 35Z"
        fill="#636F73"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M185 36C185 34.3431 186.343 33 188 33H235C236.657 33 238 34.3431 238 36V36C238 37.6569 236.657 39 235 39H188C186.343 39 185 37.6569 185 36V36Z"
        fill="#262C2D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_304_342"
          x1="92"
          y1="659"
          x2="484"
          y2="125.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0103575" stop-color="#32AAB5" />
          <stop offset="0.311419" stop-color="#758CA3" />
          <stop offset="0.788211" stop-color="#F9F9F9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
